import React, { useContext, useEffect } from "react";
import { useQuery } from '@apollo/client';
import Encart from 'components/Encart';
import Anchor from 'components/Misc/Anchor';
import Queries from 'utils/Queries';
import { SettingsContext } from 'Providers/SettingsProvider';

function Mention() {
    const { refetch,networkStatus,data } = useQuery(Queries.mention);
    const { updateLoadState,fetchedList } = useContext(SettingsContext);
    const uuid='mention';
    useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
    useEffect(()=>{
      if(fetchedList.indexOf(uuid)===-1){
        console.log('fetch',uuid);
        refetch();
      }
    },[refetch,fetchedList,uuid]);
    const rub= data ? data.rubriques[0] : null;
    return data ?  <>
        <Anchor slug={rub.slugs[0].slug} rubId={2}/>
        <Encart data={{titre:rub.titre,texte:rub.descriptif,articles:rub.articles}} template='generique' side='right'/>
    </> : '';
}

export default Mention;
