import React, { useContext } from "react";
import './Background.scss'
import Conf from 'utils/Conf';
import { SettingsContext } from 'Providers/SettingsProvider';

function Background() {
  const { root } = useContext(SettingsContext);
  let classes="background";
  Conf.special.forEach((spe)=>{
      if (root===spe.id) classes+=' '+spe.class;
  });
  return <div className={classes}>
    <div className="bas"><div/></div>
  </div>;
}
export default Background;
