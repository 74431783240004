import React from "react";
import BlocTexte from 'components/Blocs/BlocTexte';
import Blocs from 'components/Blocs/Blocs';
import {agendaDates} from 'components/Agenda/CartoucheAgenda'

function InnerActualite({actualite,types}) {
  const type=actualite.type ? (actualite.type.id ? actualite.type : types.find((evt)=>evt.id===actualite.type)) : null;
  return <>
    {actualite.affdate && <div className="actualites-evt-dates">{agendaDates(actualite)}</div>}
    <h1 style={{color:type && type.couleur}}>{actualite.title}</h1>
    <BlocTexte item={{texte:actualite.text}}/>
    <Blocs blocs={actualite.blocs}/>
  </>;
}
export default InnerActualite;
