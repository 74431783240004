import React, { useEffect, useContext } from "react";
import { useQuery } from '@apollo/client';
import Container from '@material-ui/core/Container';
import './Article.scss'
import FilAriane from 'components/Utils/FilAriane';
import InnerArticle from './InnerArticle';
import Queries from 'utils/Queries';
import { SettingsContext } from 'Providers/SettingsProvider';

function Article({id}) {
  const { updateLoadState,fetchedList } = useContext(SettingsContext);
  const { networkStatus,data,refetch } = useQuery(Queries.article,{
    variables: { id },
  });
  const article=data ? data.articles[0] : null;
  const uuid=`article${id}`;
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  useEffect(()=>document.title = article ? article.titre : '',[article]);
  return article ? <div>
  <Container maxWidth='xl'>
    <FilAriane item={article}/>
    <div className="article">
        <InnerArticle article={article}/>
    </div>
  </Container>
  <div className="bottom-padding"/>
  </div> : <div><div className="bottom-padding"/></div>;
}
export default Article;
