import React, { useEffect, useState, useCallback, useContext, useRef } from "react";
import Conf from 'utils/Conf';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import { MapContainer, TileLayer, Marker, useMap, Tooltip } from 'react-leaflet'
import Leaflet from 'leaflet';
import './Carte.scss';
import Filter from './Filter';
import ProTile from './ProTile';
import Utils from 'utils/Utils';
import {
  useParams,
  useHistory,
  useRouteMatch,
  Route,
  Switch,
  Link,
} from "react-router-dom";
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import { SettingsContext } from 'Providers/SettingsProvider';

const initialPosition=[43.8, 2];
const initialZoom=10;

const LeafIcon = Leaflet.Icon.Default;
const iconXs= new LeafIcon({
  iconSize: [18, 30],
  shadowSize: [30, 30],
  iconAnchor:[9,30],
});
const icon= new LeafIcon({});

console.log(icon);

function MyMarker({c,highlight,onHighlight,url}) {
  const {mainName}=Utils.parseContact(c);
  const { screenSize } = useContext(SettingsContext);
  const map = useMap();
  const highlightMarker=useCallback((c)=>{
    const {position}=Utils.parseContact(c);
    position && map.flyTo(position, 12);
  },[map]);
  useEffect(()=>highlight && highlightMarker(highlight),[highlightMarker,highlight]);
  return <Marker className={highlight && c.id===highlight.id ? 'selected':null} icon={['xs','sm'].indexOf(screenSize)!==-1 ? iconXs : icon} eventHandlers={{click:()=>onHighlight(c===highlight ? null : c)}} position={[c.contact.gps_y, c.contact.gps_x]}>
    {highlight && c.id===highlight.id && <Tooltip permanent direction="bottom" offset={[-15, 30]}>
      <h3 onClick={()=>onHighlight(null)}>{mainName}</h3>
      <CardActions>
        <Link to={url}>
          <Button size="small">+ d'infos</Button>
        </Link>
      </CardActions>
    </Tooltip>}
  </Marker>;
}
function MyMap({setMap}) {
  const map = useMap();
  useEffect(()=>{
    setMap(map);
  },[setMap,map])
  return null;
}
function Producteur({producteurs,setSelected,setHighlight}) {
  const selected=useRef(null);
  const { slug } = useParams();
  useEffect(()=>console.log('producteur',slug),[slug]);
  useEffect(()=>{
    if (producteurs.length>0 && slug) {
      const c=producteurs.find((o)=>Utils.filter(Utils.parseContact(o).mainName)===slug);
      if (c!==null && c!==selected.current) {
        console.log(c,slug);
        setHighlight(c);
        setSelected(c);
        selected.current=c;
      }
    }
  },[slug,producteurs,setSelected,setHighlight]);
  return null;
}
function Reset({setSelected,tous}) {
  useEffect(()=>{
    setSelected(null);
    tous();
  },[setSelected,tous]);
  return null;
}

function Carte() {
  const [ map, setMap ] =useState(null);
  const [ filtered, setFiltered ] =useState([]);
  const [ selected, setSelected ] =useState(null);
  const { appState, setAppState } = useContext(SettingsContext);
  const { hideFilter,producteurs,bounds,highlight } = appState;
  const setHighlight=(c)=>{
    setAppState(state=>{return{...state,highlight:c}});
  }
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const producteurUrl=(c)=>{
    return `${url}/`+Utils.filter(Utils.parseContact(c).mainName);
  }
  const tous=useCallback(()=>{
    console.log(bounds);
    map && bounds && map.flyToBounds(bounds, {maxZoom:12});
  },[map,bounds]);
  useEffect(()=>{
    if (map) {
      const node=map.getContainer();
      const handler=()=>{
        map.invalidateSize();
      };
      node.addEventListener('transitionend',handler);
      return ()=>{
        node.removeEventListener('transitionend',handler);
      }
    }
  },[map]);
  useEffect(()=>{
    if (!appState.fetched) {
      fetch(Conf.contactsApi+'/api.php?key='+Conf.contactsApiKey+'&q=getInstancesForm/4')
      .then(response => response.json())
      .then(data => {
        const producteurs=data.filter((o)=>o.contact && o.contact.gps_x && o.contact.gps_x<1001 && o.contact.gps_y&& o.contact.gps_x<1001);
        if (window.localStorage) {
          window.localStorage.setItem('nptarn-producteurs',JSON.stringify(producteurs));
        }
        setAppState(state=>{return{...state,producteurs,fetched:true}});
      });
    }
  },[appState.fetched,setAppState]);
  useEffect(()=>{
    if (!highlight) {
      if( bounds ) {
        map && map.invalidateSize();
        map && map.flyToBounds(bounds, {maxZoom:12});
      } else {
        map && map.invalidateSize();
        map && map.flyTo(initialPosition, initialZoom);
      }
    } else {
      const {position}=Utils.parseContact(highlight);
      map && map.invalidateSize();
      position && map && map.flyTo(position, 12);
    }
  },[highlight,map,bounds]);
  useEffect(()=>{
    map && map.invalidateSize();
  },[map,hideFilter,selected,filtered]);
  useEffect(()=>setFiltered(producteurs),[setFiltered,producteurs]);
  return <>
  <div className="carte">
  <div className={"carte-inner"+(hideFilter && !selected ? ' fullwidth' : '')+(selected ? ' selected' : '')}>
  <MapContainer className="carte-map" center={initialPosition} zoom={initialZoom} scrollWheelZoom={true} zoomControl={false}>
    <TileLayer
      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    {filtered.map((c,i)=><React.Fragment key={i}>
        {c.contact && c.contact.gps_x && c.contact.gps_y && <MyMarker c={c} highlight={highlight} onHighlight={setHighlight} url={producteurUrl(c)}/>}
      </React.Fragment>
    )}
    <MyMap setMap={setMap}/>
  </MapContainer>
  {selected ?
    <ProTile c={selected} onClose={()=>history.push(url)} schema={producteurs[0].schema}/>
    :
    <Filter highlight={highlight} liste={producteurs} onFiltered={setFiltered} producteurUrl={producteurUrl} onHighlight={setHighlight}/>
  }
  </div>
  </div>
  <Switch>
    <Route exact path={`${path}`}>
      <Reset setSelected={setSelected} tous={tous}/>
    </Route>
    <Route path={`${path}/:slug`}>
      <Producteur producteurs={producteurs} setSelected={setSelected} setHighlight={setHighlight}/>
    </Route>
  </Switch>
  </>;
}
export default Carte;
