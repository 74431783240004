import React, { useContext, useEffect } from "react";
import InnerActualite from 'components/Actualites/InnerActualite';
import { useQuery } from '@apollo/client';
import Queries from 'utils/Queries';
import './Preview.scss';
import 'components/Article/Article.scss';
import { SettingsContext } from 'Providers/SettingsProvider';

function PreviewActualite({ actualite }) {
  const { updateLoadState,fetchedList } = useContext(SettingsContext);
  const { networkStatus,data,refetch } = useQuery(Queries.actualite,{
    variables: { id:actualite.id },
  });
  const uuid=`actualite${actualite.id}`;
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  const actualiteServer=data ? data.agenda[0] : null;
  console.log(actualite,actualiteServer);
  const types=data ? data.evt_type : [];
  if (actualiteServer) {
      let {blocs:blocsServer,...othersServer} = actualiteServer;
      let {blocs,...others} = actualite;
      let actualiteUpdated={...othersServer,...others,blocs:[]};
      if (blocs.length>0) {
          blocs.forEach((b,i)=>{
              if (b.collection) {
                let bs=blocsServer.find((o)=>o.id===b.id);
                let { item, ...otherProps } = b;
                if (bs) {
                  let { item:itemServer, ...otherPropsServer } = bs;
                  if (item.documents && itemServer.documents) {
                      let newDocuments=item.documents.map((doc)=>doc.directus_files_id ? doc : itemServer.documents.find((docServer)=>doc===docServer.id));
                      item.documents=[...newDocuments];
                  }
                  actualiteUpdated.blocs.push({item:{...itemServer,...item},...otherPropsServer,...otherProps});
                } else {
                  actualiteUpdated.blocs.push({item:{...item,__typename:b.collection},...otherProps});
                }
              } else {
                actualiteUpdated.blocs.push(blocsServer.find((o)=>o.id===b));
              }
          });
      }
      actualiteUpdated.blocs.sort((a,b)=>a.sort-b.sort);
      return <div className="actualite">
        <InnerActualite actualite={actualiteUpdated} types={types}/>
      </div>;
  } else {
    return '';
  }
}
export default PreviewActualite;
