import React, { useContext } from "react";
import SlugLink from 'components/Utils/SlugLink';
import './FilAriane.scss';
import { SettingsContext } from 'Providers/SettingsProvider';

function FilAriane({item}) {
    const { getAriane, getPath } = useContext(SettingsContext);
    const { ariane } = getAriane(getPath(item));
    return ariane ? <div className="ariane">{ariane.map((item,i)=>{
        let inter='';
        if (i>0) inter=<> > </>;
        return i===ariane.length-1 ? <React.Fragment key={item.__typename+item.id}>{inter}{item.titre}</React.Fragment> : <React.Fragment key={item.__typename+item.id}>{inter}<SlugLink item={item}>{item.titre}</SlugLink></React.Fragment>;
    })}</div> : '';
}

export default FilAriane;
