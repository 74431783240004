import React from "react";
import {
  Link
} from "react-router-dom";

function CartoucheCarte({
    texte1='',
    texte2='',
    fontSize='30',
}) {
  return <div className="cartouche-carte">
      <div className="cartouche-carte-textes">
          <div className="shape-mask"></div>
          <Link to="/carte">
          <div className="cartouche-carte-texte1" style={{fontSize:(fontSize*0.4)+'px'}}>{texte1}</div>
          </Link>
          <Link to="/carte">
          <div className="cartouche-carte-texte2" style={{fontSize:(fontSize*0.225)+'px'}}>{texte2}</div>
          </Link>
        </div>
      <Link to="/carte"><div className="dessin-carte"></div></Link>
  </div>;
}

export default CartoucheCarte;
