import React from "react";
import './Blocs.scss';
import SwiperCore, { Navigation, Pagination, A11y } from 'swiper';
import Conf from 'utils/Conf';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, A11y]);

function BlocDiaporama({item}) {
  const {images} = item || {};
  return images ? <div className="bloc">
      <div className="bloc-diaporama">
          <Swiper
          navigation
          pagination={{ clickable: true }}
          >
            {images.map((image,i)=>{
              const imageId=image.directus_files_id.id || image.directus_files_id;
              return <SwiperSlide key={imageId}><img src={`${Conf.apiUrl}/assets/${imageId}?key=diaporama`} alt=""/></SwiperSlide>
            })}
          </Swiper>
      </div>
  </div> : '';
}
export default BlocDiaporama;
