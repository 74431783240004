import React from "react";
import './CartoucheImage.scss';
function CartoucheImage({ texte, url, fontSize }) {
    const style={
        backgroundImage:`url(${url})`,
    };
    return <div className='cartouche-image' style={style}>
        {texte && <div className='cartouche-image-texte'>
            <div className='cartouche-image-texte-inner' style={{fontSize:fontSize+'px'}}>
                {texte}
            </div>
        </div>}
    </div>;
}

export default CartoucheImage;
