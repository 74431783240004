import React from "react";
import SlugLink from 'components/Utils/SlugLink';
import CartoucheImage from 'components/Misc/CartoucheImage';
import CartoucheCarte from 'components/Encart/CartoucheCarte';
import Conf from 'utils/Conf';
import Utils from 'utils/Utils';

import './TplAsso.scss';
function TplAsso({ data, fontSize }) {
  const { rub, art1, art2, carte1, carte2 } = data;
  return <div className='asso-layout'>
      <div className='asso-layout-inner'>
          <div className='asso-layout-0'>
              {rub && <h3 style={{fontSize:(fontSize*0.2)+'px'}}>{rub.titre}</h3>}
              {rub && <div style={{fontSize:(fontSize*0.1)+'px'}}>{Utils.parseWithLinks(rub.descriptif)}</div>}
          </div>
          <SlugLink item={art1} type='article'>
              <div className='asso-layout-1'>
                  {art1 && <CartoucheImage texte={art1.titre} url={art1.visuel ? `${Conf.apiUrl}/assets/${art1.visuel.id}?key=medium` : null } fontSize={fontSize*0.1}/>}
              </div>
          </SlugLink>
          <SlugLink item={art2} type='article'>
              <div className='asso-layout-2'>
                  {art2 && <CartoucheImage texte={art2.titre} url={art2.visuel ? `${Conf.apiUrl}/assets/${art2.visuel.id}?key=medium` : null } fontSize={fontSize*0.1}/>}
              </div>
          </SlugLink>
          <div className='asso-layout-3'>
              <CartoucheCarte texte1={carte1} texte2={carte2} fontSize={fontSize*0.6}/>
          </div>
      </div>
      </div>;
}

export default TplAsso;
