import React, { useEffect, useContext } from "react";
import { useQuery } from '@apollo/client';
import Container from '@material-ui/core/Container';
import Conf from 'utils/Conf';
import Queries from 'utils/Queries';
import './Accueil.scss'
import CartoucheAgenda from 'components/Agenda/CartoucheAgenda';
import Evenements from 'components/Evenements/Evenements';
import Engager from 'components/Engager/Engager';
import Asso from 'components/Encart/Asso';
import Mention from 'components/Encart/Mention';
import Contact from 'components/Encart/Contact';
import { SettingsContext } from 'Providers/SettingsProvider';
import {
  Link
} from "react-router-dom";

import SwiperCore, { Pagination, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

// install Swiper modules
SwiperCore.use([Pagination, A11y, Autoplay]);


function Accueil() {
  const { refetch,networkStatus,data } = useQuery(Queries.accueil);
  const { updateLoadState,fetchedList } = useContext(SettingsContext);
  const uuid='accueil';
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  const diaporama=data && data.slide ? data.slide : [];
  const phrase=data && data.interface[0] ? data.interface[0].value : '';
  return data ? <div className="accueil">
    <CartoucheAgenda />
    <div className="diaporama">
        <Swiper
        autoplay={{delay:5000,disableOnInteraction:false}}
        speed={1000}
        loop={diaporama.length>1}
        watchOverflow
        pagination={{ clickable: true }}
        >
          {diaporama.map((slide,i)=>{
            const imageId=slide.image.id;
            const slideContent=<>
              <img src={`${Conf.apiUrl}/assets/${imageId}?key=bandeau-wide`} alt=""/>
              {slide.titre && <div className="slide-titre">{slide.titre}</div>}
            </>;
            const isExternal=slide.lien && slide.lien.indexOf('://')!==-1;
            return <SwiperSlide key={imageId}>
                {slide.lien ? (
                    isExternal ?
                    <a href={slide.lien}>
                        {slideContent}
                    </a>
                    :
                    <Link to={slide.lien}>
                        {slideContent}
                    </Link>
                ) : slideContent}
            </SwiperSlide>
          })}
        </Swiper>
    </div>
    <div className="jolie-phrase">
        <div className="inner">
            {phrase}
        </div>
    </div>
    <div className='main-container'>
        <Container maxWidth='xl'>
            <Asso/>
            <Mention/>
            <div className="spacer"/>
            </Container>
    </div>
    <Evenements accueil/>
    <div className='main-container'>
        <Container maxWidth='xl'>
            <Engager/>
            <Contact/>
        </Container>
    </div>
    <div className="bottom-padding"/>
  </div>:<div/>;
}
export default Accueil;
