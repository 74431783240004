import React from "react";
import './Blocs.scss';
import BlocTexte from './BlocTexte';
import BlocDiaporama from './BlocDiaporama';
import BlocTexteImage from './BlocTexteImage';
import BlocImages from './BlocImages';
import BlocDocuments from './BlocDocuments';
import BlocSeparateur from './BlocSeparateur';

function Blocs({blocs}) {
  return blocs ? blocs.map((b,i)=>b ? <React.Fragment key={i}>
      {b.item.__typename==='bloc_texte' && <BlocTexte item={b.item}/>}
      {b.item.__typename==='bloc_diaporama' && <BlocDiaporama item={b.item}/>}
      {b.item.__typename==='bloc_texteimage' && <BlocTexteImage item={b.item}/>}
      {b.item.__typename==='bloc_images' && <BlocImages item={b.item}/>}
      {b.item.__typename==='bloc_separateur' && <BlocSeparateur/>}
      {b.item.__typename==='bloc_documents' && <BlocDocuments item={b.item}/>}
  </React.Fragment> : '') : '';
}
export default Blocs;
