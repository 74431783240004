import React, { useState, useEffect } from "react";
import PreviewArticle from './PreviewArticle';
import PreviewActualite from './PreviewActualite';
import './Preview.scss';
import 'components/Article/Article.scss';

function Preview() {
  const [ values, setValues ] = useState({});
  const [ fields, setFields ] = useState([]);
  const handleMessage=(msg)=>{
      if (msg.data.type && msg.data.type==='preview'){
        setValues(msg.data.values);
        setFields(msg.data.fields);
        console.log(msg.data);
      }
      if (window.parent) window.parent.postMessage("ok", "*");
  }
  useEffect(()=>{
    window.addEventListener('message', handleMessage);
    return ()=>window.removeEventListener('message', handleMessage);
  },[]);
  const collection=(fields.length>0 && fields[0].collection) || '';
  return <div className="preview">
  {collection==='articles' && <PreviewArticle article={values}/>}
  {collection==='agenda' && <PreviewActualite actualite={values}/>}
  </div>;
}
export default Preview;
