import React, { useState } from "react";
import './Encart.scss';
import TplAsso from './TplAsso';
import TplMention from './TplMention';
import TplContact from './TplContact';
import TplGenerique from './TplGenerique';
import FontSizer from 'components/Misc/FontSizer';

const templates={
    asso:TplAsso,
    mention:TplMention,
    contact:TplContact,
    generique:TplGenerique,
}

function Encart({ data, side='left', template }) {
  const [ fontSize,setFontSize ] = useState(20);
  let classes='encart';
  if (side==='right') classes+=' right';
  const TplComponent=templates[template];
  return <div className={classes}>
      <div className='encart-inner'>
          <div>
              {TplComponent && <TplComponent data={data} fontSize={fontSize} side={side}/>}
              <FontSizer onChange={setFontSize}/>
          </div>
      </div>
  </div>;
}

export default Encart;
