import React from "react";
import './Image.scss';

function Image({url,ratio=1,params={}}) {
  return <div className="image-container" style={{paddingBottom:(ratio*100)+'%'}}>
      {Boolean(url) ? <>
        <img alt="" src={url} style={{objectFit:params.size,objectPosition:params.size==='cover' ? params.left+'% '+params.top+'%' : null}}/>
      </> : <div className="image-placeholder"/>}
  </div>;
}

export default Image;
