import React, { useEffect, useState, useContext, useCallback, useMemo, useRef } from "react";
import Conf from 'utils/Conf';
import { SettingsContext } from 'Providers/SettingsProvider';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Pagination from '@material-ui/lab/Pagination';
import Image from 'components/Image';
import Utils from 'utils/Utils';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import {
  Link,
} from "react-router-dom";
import _ from 'lodash';
import './Filter.scss';
const filterPaths=[
  'contact.nom',
  'contact.prenom',
  'contact.adresse.adresse',
  'contact.adresse.ville',
  'contact.adresse.cp',
]
const filterInstanceNames=[
  'prods',
  'nom',
  'prenom',
]
const toString=(o)=>{
  if (o.valeur && typeof o.valeur == 'string') return o.valeur;
  else {
    let res='';
    Object.keys(o).forEach((k)=>{
      if (typeof o[k] == 'object') res=res+' '+toString(o[k]);
    });
    return res;
  }
}
const itemsPerPageByScreenSize={
  xl:6,
  lg:4,
  md:6,
  sm:4,
  xs:4,
};
const filterListe=(filter,liste)=>{
  const { text,schema } = filter;
  return liste.filter((o)=>{
    let testValid=false;
    o.forms.forEach((form) => {
      const {mainName}=Utils.parseContact(o);
      form.instances.forEach((instance) => {
        if (mainName && mainName.trim()!=='') testValid=true;
      });
    });
    if(!testValid) return false;
    let testFilter=false;
    if (text==='') {
      testFilter=true;
    } else {
      const textTab=text.split(' ');
      filterPaths.forEach((path)=>{
        const s=_.get(o,path);
        textTab.forEach((t)=>{
          if (Utils.accentsTidyLw(s).indexOf(Utils.accentsTidyLw(t))!==-1) testFilter=true;
        });
      });
      o.forms.forEach((form) => {
        form.instances.forEach((instance) => {
          filterInstanceNames.forEach((name)=>{
            const nom=instance.data.find((item)=>item.nom===name);
            const s=nom ? toString(nom) : '';
            textTab.forEach((t)=>{
              if (Utils.accentsTidyLw(s).indexOf(Utils.accentsTidyLw(t))!==-1) testFilter=true;
            });
          });
        });
      });
    }
    let schemaFilter=true;
    Object.keys(schema).forEach((k) => {
      let choiceFilter=false;
      const choices=schema[k].map((o)=>o.valeur);
      if (choices.length===0) {
        choiceFilter=true;
      } else {
        const [ id,subId ] = k.split('/');
        let value=[];
        if (subId) {
          value=[];
          o.forms[0].instances[0].data.forEach((item)=>{
            if (item.id===id) {
              item.valeur.tab.forEach((ligne) => {
                if (ligne[subId]) value=[...value,...ligne[subId].valeur.split(',')];
              });
            }
          });
        } else {
          const elt=o.forms[0].instances[0].data.find((o)=>o.id===id);
          if (elt) value=elt.valeur.split(',');
        }
        if (value.length>0) {
          choices.forEach((choice) => {
            choiceFilter=choiceFilter || value.indexOf(choice)!==-1;
          });
        }
      }
      schemaFilter=schemaFilter && choiceFilter;
    });
    return testFilter && schemaFilter;
  });
}

function Filter({active,liste,onFiltered,producteurUrl=()=>{},highlight,onHighlight=()=>{}}) {
  const { t, appState, setAppState, screenSize, site } = useContext(SettingsContext);
  const { cartePage, hideFilter, filter, bounds } = appState;
  const [ filtered, setFiltered ] =useState(filterListe(filter,liste));
  const filteredCache=useRef(filtered);
  const itemsPerPage=screenSize ? itemsPerPageByScreenSize[screenSize] : 6;
  const nbPages=Math.ceil(filtered.length/itemsPerPage);
  const label=(o)=>{
    const trad=t('form-'+Utils.filter(o.name));
    return trad.length!==0 ? trad : o.label
  }
  const choix=(o)=>{
    const trad=t('choix-'+Utils.filter(o.valeur));
    return trad.length!==0 ? trad : o.nom
  }
  const schema=useMemo(()=>{
    const res=liste && liste[0] && liste[0].schema;
    const filterMap=[];
    if (res) {
      res.forEach((page) => {
        page.elts.forEach((elt) => {
          if(elt.type==='multiples' && Conf.proExclude.indexOf(elt.name)===-1) {
            const { name,id,label,choix,nbMax } = elt;
            filterMap.push({name,id,label,choix,nbMax});
          }
          if(elt.type==='tab') {
            elt.elts.forEach((eltSub) => {
              if(eltSub.type==='multiples' && Conf.proExclude.indexOf(eltSub.name)===-1) {
                const { name,id,label,choix,nbMax } = eltSub;
                filterMap.push({name,id:elt.id+'/'+id,label,choix,nbMax});
              }
            });
          }
        });
      });
    }
    return filterMap;
  },[liste]);
  const handleChangeAutocomplete = (value,key) => {
    setAppState(state=>{
      const item=schema.find((o)=>o.id===key);
      return {
        ...state,
        filter:{
          ...state.filter,
          schema:{
            ...state.filter.schema,
            [key]:value.slice(Math.max(0,value.length-item.nbMax)),
          }
        }
      }
    });
  };
  const setFilter = useCallback((f)=>{
    setAppState(state=>{return{...state,filter:f}});
  },[setAppState]);
  const setBounds = useCallback((bounds)=>{
    setAppState(state=>{return{...state,bounds}});
  },[setAppState]);
  const handlePageChange=useCallback((e, value)=>{
    setAppState(state=>{return{...state,cartePage:value}});
  },[setAppState]);
  const toggleHideFilter=useCallback(()=>{
    setAppState(state=>{return{...state,hideFilter:Boolean(!state.hideFilter)}});
  },[setAppState]);
  const handleChange=useCallback((key,value)=>{
    setFilter({...filter,[key]:value})
  },[setFilter,filter]);
  const doFilter=useCallback((filter)=>{
    const filtered=filterListe(filter,liste);
    setFiltered(filtered);
    onFiltered(filtered);
  },[liste,setFiltered,onFiltered]);
  const filterDebounced=useMemo(()=>_.debounce(doFilter,500),[doFilter]);
  useEffect(()=>{
    filterDebounced(filter);
    return ()=>filterDebounced.cancel();
  },[filter,filterDebounced]);
  useEffect(()=>{
    if (!_.isEqual(filteredCache.current,filtered) || !bounds) {
      handlePageChange({},1);
      onHighlight(null);
      filteredCache.current=filtered;
      let minLat,maxLat,minLn,maxLn;
      filtered.forEach((c) => {
        const data=Utils.parseContact(c);
        if(!minLat || data.position[0]<minLat) minLat=data.position[0];
        if(!maxLat || data.position[0]>maxLat) maxLat=data.position[0];
        if(!minLn || data.position[1]<minLn) minLn=data.position[1];
        if(!maxLn || data.position[1]>maxLn) maxLn=data.position[1];
      });
      console.log({minLat,maxLat,minLn,maxLn});
      minLat && maxLat && minLn && maxLn && setBounds([
          [minLat, minLn],
          [maxLat, maxLn]
      ]);
    }
  },[filtered,handlePageChange,setBounds,onHighlight,bounds]);
  return <div className={"carte-filter"+(hideFilter ? ' hide' : '')+(active ? ' active' : '')}>
    <h3>{t('carte-professionnels')}</h3>
    <div className="spacer-mini"></div>
    <TextField variant="outlined" size="small" fullWidth label='Rechercher' className="form-select" onChange={(e)=>handleChange('text',e.target.value)} type="text" value={filter.text}/>
    <Grid container spacing={1} className="schema-filters">
    {schema.map((o)=><Grid key={o.id} item xs={12} md={6}>
        <Autocomplete
        fullWidth
        multiple
        size="small"
        value={appState.filter.schema[o.id] && appState.filter.schema[o.id].length>0 ? appState.filter.schema[o.id] : []}
        onChange={(e,v)=>handleChangeAutocomplete(v,o.id)}
        options={o.choix}
        getOptionLabel={(option) => choix(option)}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip key={option.id}
              {...getTagProps({ index })}
              label={choix(option)}
            />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} label={label(o)} variant="outlined" />
        )}
      />
    </Grid>)}
    </Grid>
    <div className="spacer-mini"></div>
    <Pagination size="small" count={nbPages} page={cartePage || 1} onChange={handlePageChange}/>
    <div className="spacer-mini"></div>
    <div className="spacer-mini"></div>
    <Grid container spacing={3} className="liste-producteurs">
      {filtered.filter((o,i)=>i>=(cartePage-1)*itemsPerPage && i<(cartePage)*itemsPerPage).map((c)=>{
        const {diaporama,mainName}=Utils.parseContact(c);
        return <Grid key={c.id} item xs={6} md={4} lg={6} xl={4} className={'producteur'+(highlight===c ? ' highlight':'')} onClick={()=>onHighlight(highlight===c ? null : c)}>
          <Card>
            <Image url={diaporama[0] ? Conf.contactsApi+'/'+diaporama[0].url : (site.image && site.image.id ? `${Conf.apiUrl}/assets/${site.image.id}` : null)} ratio={0.5} params={diaporama[0] && diaporama[0].params}/>
            <CardContent>
              <h3>{mainName}</h3>
            </CardContent>
            <CardActions>
              <Link to={producteurUrl(c)}>
                <Button size="small">+ d'infos</Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>;
      })}
      <Grid item xs={12}>
        <div className="spacer-mini"></div>
        <Pagination size="small" count={nbPages} page={cartePage || 1} onChange={handlePageChange}/>
      </Grid>
    </Grid>
    <div className="languette" onClick={toggleHideFilter}>
      <div>
        {hideFilter ? <KeyboardArrowRightIcon/> : <KeyboardArrowLeftIcon/>}
      </div>
    </div>
  </div>;
}
export default Filter;
