import React, { useEffect, useContext } from "react";
import { useQuery } from '@apollo/client';
import Container from '@material-ui/core/Container';
import './Actualites.scss'
import Queries from 'utils/Queries';
import { SettingsContext } from 'Providers/SettingsProvider';
import {
  Link
} from "react-router-dom";
import InnerActualite from './InnerActualite';
import CartoucheAgenda from 'components/Agenda/CartoucheAgenda';

function Actualite({id}) {
  const { t,setCurrentRub,updateLoadState,fetchedList } = useContext(SettingsContext);
  const { refetch,networkStatus,data } = useQuery(Queries.actualite,{
    variables: { id },
  });
  const uuid=`actualite${id}`;
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  const actualite=data ? data.agenda[0] : null;
  const types=data ? data.evt_type : [];
  useEffect(()=>setCurrentRub(0),[setCurrentRub]);
  useEffect(()=>document.title = actualite ? actualite.title : '',[actualite]);
  return actualite ? <div>
  <CartoucheAgenda small/>
  <Container maxWidth='xl'>
    <div className="ariane">
      <Link to="/actualites">{t('actualites')}</Link> {actualite.type && <>{">"} <span style={{color:actualite.type.couleur}}>{actualite.type.type}</span></>} > {actualite.title}
    </div>
    <div className="actualite">
        <InnerActualite actualite={actualite} types={types}/>
    </div>
  </Container>
  <div className="bottom-padding"/>
  </div> : <div><div className="bottom-padding"/></div>;
}
export default Actualite;
