import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from '@apollo/client';
import {
  useRouteMatch,
  useLocation,
  Route,
  Switch,
} from "react-router-dom";
import Rubrique from 'components/Rubrique/Rubrique';
import Article from 'components/Article/Article';
import Actualite from 'components/Actualites/Actualite';
import Queries from 'utils/Queries';
import {
  TransitionGroup,
  CSSTransition
} from "react-transition-group";
import { SettingsContext } from 'Providers/SettingsProvider';

function SubRoutes ({
  root=false,
  id,
  localRoutes=[],
  depth=0
}) {
  const [ routesOk, setRoutesOk ] = useState([]);
  let { path } = useRouteMatch();
  const query= root ? Queries.subroutesroot : Queries.subroutes;
  const { updateLoadState,getAriane,fetchedList } = useContext(SettingsContext);
  const { networkStatus,data,refetch } = useQuery(query,{
    variables: { id },
  });
  const uuid=`subroute${id ? id : ''}`;
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  const { pathname, search, state } = useLocation();
  let sameRub=false;
  if (root && state && state.from) {
      let {ariane:arianeCurrent}=getAriane(pathname);
      let {ariane:arianePrevious}=getAriane(state.from);
      if (
        arianeCurrent[0]
        && arianeCurrent[0].__typename==="rubriques"
        && arianePrevious[0]
        && arianePrevious[0].__typename==="rubriques"
        && arianeCurrent[0].id===arianePrevious[0].id
      ) {
        sameRub=true;
      }
  }
  useEffect(()=>{
      const rubriques = data ? data.rubriques : [];
      const articles = data ? data.articles : [];
      const agenda = data && data.agenda ? data.agenda : [];
      let res=[];
      let currentPath= path==='/' ? '' : path;
      rubriques.forEach((rub, i) => {
          res.push(<Route key={`${currentPath}/${rub.id}`} exact path={`${currentPath}/${rub.id}`}>
              <Rubrique id={rub.id} pathname={pathname} exact/>
          </Route>);
          res.push(<Route key={`${currentPath}/${rub.id}`} path={`${currentPath}/${rub.id}`}>
              <Rubrique id={rub.id} pathname={pathname}/>
          </Route>);
          if (rub.slugs && rub.slugs.length>0) {
              rub.slugs.forEach((slug, i) => {
                res.push(<Route key={`${currentPath}/${slug.slug}`} exact path={`${currentPath}/${slug.slug}`}>
                    <Rubrique id={rub.id} exact pathname={pathname}/>
                </Route>);
                res.push(<Route key={`${currentPath}/${slug.slug}`} path={`${currentPath}/${slug.slug}`}>
                    <Rubrique id={rub.id} pathname={pathname}/>
                </Route>);
            });
          }
      });
      articles.forEach((art, i) => {
          res.push(<Route key={`${currentPath}/article-${art.id}`} path={`${currentPath}/article-${art.id}`}>
              <Article id={art.id}/>
          </Route>);
          if (art.slugs && art.slugs.length>0) {
              art.slugs.forEach((slug, i) => {
                  res.push(<Route key={`${currentPath}/article-${slug.slug}`} path={`${currentPath}/article-${slug.slug}`}>
                      <Article id={art.id}/>
                  </Route>);
              });
          }
      });
      agenda.forEach((evt, i) => {
          res.push(<Route key={`/actualites/${evt.id}`} path={`/actualites/${evt.id}`}>
              <Actualite id={evt.id}/>
          </Route>);
          if (evt.slugs && evt.slugs.length>0) {
              evt.slugs.forEach((slug, i) => {
                  res.push(<Route key={`/actualites/${slug.slug}`} path={`/actualites/${slug.slug}`}>
                      <Actualite id={evt.id}/>
                  </Route>);
              });
          }
      });
      setRoutesOk([...res, ...localRoutes]);
  },[data,setRoutesOk,path,localRoutes, depth, pathname]);
  return root ? <div className={"subroutes"+(sameRub ? " samerub" : " differentrub")}>
    <TransitionGroup appear>
        <CSSTransition
        key={pathname.startsWith('/carte') ? '/carte' : pathname}
        classNames={"fade"}
        timeout={3000}
        addEndListener={(node, done) => {
          // use the css transitionend event to mark the finish of a transition
          node.addEventListener('transitionend', (e)=>{
            if (e.target===node) {
                done(e);
            }
          }, false);
        }}
        >
            <Switch location={{ pathname, search, state, hash:"" }}>
                {routesOk}
            </Switch>
        </CSSTransition>
    </TransitionGroup>
    </div>
    :
    <div className="subroutes">
        <Switch>
            {routesOk}
        </Switch>
    </div>;
};

export default SubRoutes;
