import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Conf from 'utils/Conf';
import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { persistCacheSync, LocalStorageWrapper } from 'apollo3-cache-persist';

if ('serviceWorker' in navigator) {
  console.log("serviceWorker found")
    navigator.serviceWorker
    .register('/service-worker.js', { scope: '/' })
    .then(function(registration) {
      console.log("Service Worker Registered");
    })
    .catch(function(err) {
      console.log("Service Worker Failed to Register", err);
    });
    navigator.serviceWorker.addEventListener('message', function(event) {

    });
} else {
  console.log("serviceWorker not found")
}

const httpLink = createHttpLink({
  uri: Conf.apiUrl+"/graphql",
});
const httpLinkSystem = createHttpLink({
  uri: Conf.apiUrl+"/graphql/system",
});
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: Conf.authToken ? `Bearer ${Conf.authToken}` : "",
    }
  }
});
const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        items: {
          merge: (existing, incoming, opts) =>
            opts.mergeObjects(existing, incoming),
        },
      },
    },
  },
});
if (window.localStorage) {
  persistCacheSync({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
    key:'nptarn-appolo',
  });
}
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});
const clientSystem = new ApolloClient({
  link: authLink.concat(httpLinkSystem),
  cache,
});
ReactDOM.render(
  <ApolloProvider client={client}>
    <App clientSystem={clientSystem}/>
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
