import React, { useContext, useRef, useEffect, useState } from "react";
import { SettingsContext } from 'Providers/SettingsProvider';

function Anchor ({
  slug, rubId, className
}) {
  const [ scrollY, setScrollY ] = useState(0);
  const handleScroll=()=>{
      setScrollY(window.scrollY);
  };
  useEffect(()=>{
      document.addEventListener('scroll',handleScroll);
      return ()=>document.removeEventListener('scroll',handleScroll);
  },[]);
  const ref=useRef();
  const { currentRub, setCurrentRub, loaded } = useContext(SettingsContext);
  useEffect(()=>{
    if (ref.current) {
      let node=ref.current;
      let target= node.nextElementSibling || node.nextSibling;
      let rect=target.getBoundingClientRect();
      if (rect.top<window.innerHeight/2 && rect.top+rect.height>window.innerHeight/2 && currentRub!==rubId) {
        const fadeExit=document.querySelector(".fade-exit");
        const fadeExitActive=document.querySelector(".fade-exit-active");
        if (!fadeExit && !fadeExitActive) setCurrentRub(rubId);
      }
    }
  },[scrollY,currentRub,setCurrentRub,rubId,loaded]);
  const classes= className || "anchor";
  return <div id={slug} className={classes} ref={ref}></div>
};

export default Anchor;
