import React from "react";
import './CartoucheImageRond.scss';
function CartoucheImageRond({ url, side }) {
    const style={
        backgroundImage:`url(${url})`,
    };
    let classes="cartouche-image-rond-fond";
    if (side==='left') classes+=" cartouche-image-rond-fond-gauche";
    else classes+=" cartouche-image-rond-fond-droit";
    return <div className='cartouche-image-rond'>
        <div className={classes}></div>
        <div className='cartouche-image-rond-inner' style={style}></div>
    </div>;
}

export default CartoucheImageRond;
