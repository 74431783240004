import React from "react";
import './Blocs.scss';
import Grid from '@material-ui/core/Grid';
import Conf from 'utils/Conf';
import Utils from 'utils/Utils';

function BlocTexteImage({item}) {
  const {texte,image,side,document,link} = item || {};
  const imageId=image ? (image.id || image) : null;
  const cote= side || 'left';
  const renderTexte=<Grid item xs={12} sm={6}>
    <div>{Utils.parseWithLinks(texte)}</div>
  </Grid>;
  const renderImg=<Grid item xs={12} sm={6} className="image">
    {imageId ? (
      link ?
        <a href={link} target="_blank" rel="noreferrer"><img src={`${Conf.apiUrl}/assets/${imageId}?key=diaporama`} alt=""/></a>
        : (
          document && document.id ?
            <a href={`${Conf.apiUrl}/assets/${document.id}`} target="_blank" rel="noreferrer"><img src={`${Conf.apiUrl}/assets/${imageId}?key=diaporama`} alt=""/></a>
            : <img src={`${Conf.apiUrl}/assets/${imageId}?key=diaporama`} alt=""/>
        )

    ) : ''}
  </Grid>;
  return <div className={"bloc bloc-texteimage texteimage-"+cote}>
  <Grid container spacing={2}>
    {cote==='left' && <>
      {renderImg}
      {renderTexte}
    </>}
    {cote==='right' && <>
      {renderTexte}
      {renderImg}
    </>}
  </Grid>
  </div>;
}
export default BlocTexteImage;
