import parse, { domToReact } from 'html-react-parser';
import { Link } from 'react-router-dom';
const nbsp=(text)=>{
  let res=text+'';
  res=res.replace(/[ ]+!/gm,"&nbsp;!");
  res=res.replace(/[ ]+\?/gm,"&nbsp;?");
  res=res.replace(/[ ]+:/gm,"&nbsp;:");
  res=res.replace(/[ ]+;/gm,"&nbsp;;");
  return res;
}
const truncateString = (str, num)=>{
  let tab=str.split(' ');
  let res='';
  tab.some((word, i) => {
    res= i===0 ? word : res+' '+word;
    return res.length>num;
  });
  return res===str ? str : res+'...';
};
const parseWithLinks = (text)=>{
  if (text) {
    let res=nbsp(text);
    const options = {
      replace: ({ name, attribs, children }) => {
        if (
          name === 'a'
          && attribs.href
          && attribs.href.indexOf('://')===-1
          && attribs.href.indexOf('mailto:')===-1
        ) {
          return <Link to={attribs.href}>{domToReact(children)}</Link>;
        }
      }
    };
    return parse(res, options);
  }
  return '';
}
const cumulativeOffset = (element)=>{
    let top = 0, left = 0;
    do {
        top += element.offsetTop  || 0;
        left += element.offsetLeft || 0;
        element = element.offsetParent;
    } while(element);
    return {
        top: top,
        left: left
    };
}
const jump = (h)=>{
    const node = document.getElementById(h);
    let top=0;
    if (node) {
        top=cumulativeOffset(node).top;
    }
    window.scrollTo(0, top-15);
}
const path2type = (path)=>{
    const tab=path.split('/');
    const last=tab[tab.length-1];
    const tabLast=last.split('-');
    return tabLast[0];
}
const parseContact=(c)=>{
  const nom=(c.forms[0].instances[0].data.find((o)=>o.nom==='nom') || {valeur:''}).valeur.toUpperCase();
  const prenom=(c.forms[0].instances[0].data.find((o)=>o.nom==='prenom') || {valeur:''}).valeur;
  const autresNoms=(c.forms[0].instances[0].data.find((o)=>o.nom==='tab_autre_pers') || {valeur:{tab:[]}}).valeur.tab.map(o=>{return{prenom:o['o2he371u2'].valeur,nom:o['2yvzji3fm'].valeur.toUpperCase()}});
  const raison=(c.forms[0].instances[0].data.find((o)=>o.nom==='raison_soc') || {valeur:''}).valeur;
  const adresse=(c.forms[0].instances[0].data.find((o)=>o.nom==='ad') || {valeur:''}).valeur;
  const cp=(c.forms[0].instances[0].data.find((o)=>o.nom==='cp') || {valeur:''}).valeur;
  const ville=(c.forms[0].instances[0].data.find((o)=>o.nom==='ville') || {valeur:''}).valeur;
  const mail=(c.forms[0].instances[0].data.find((o)=>o.nom==='mail') || {valeur:''}).valeur;
  const tel=(c.forms[0].instances[0].data.find((o)=>o.nom==='tel') || {valeur:''}).valeur;
  const descriptif=(c.forms[0].instances[0].data.find((o)=>o.nom==='descriptif') || {valeur:''}).valeur;
  const diaporama=(c.forms[0].instances[0].data.find((o)=>o.nom==='diaporama') || {valeur:[]}).valeur;

  const fullName=`${prenom} ${nom}`+autresNoms.map((o)=>(o.prenom+o.nom)!=='' ? `, ${o.prenom} ${o.nom}` : '').join('');

  return {
    fullName,
    raison,
    mainName:raison ? raison : fullName,
    adresse,
    cp,
    ville,
    mail,
    tel,
    descriptif,
    diaporama,
    data:c.forms[0].instances[0].data,
    position:[c.contact.gps_y, c.contact.gps_x],
  }
}
const accentsTidy=(s='')=>{
    var map = [
        ["[àáâãäåāăąǎǟǡǻȁȃȧ]", "a"],
        ["[æ]", "ae"],
        ["[ƀƃɓ]", "b"],
        ["[çćĉċčƈȼɕ]", "c"],
        ["[ďđƌȡɖɗ]", "d"],
        ["[èéêëēĕėęěȅȇȩɇ]", "e"],
        ["[ƒ]", "f"],
        ["[ĝğġģǥǧǵɠ]", "g"],
        ["[ĥħȟɦ]", "h"],
        ["[ìíîïĩīĭįǐȉȋɨ]", "i"],
        ["[ĵǰɉʝ]", "j"],
        ["[ķƙǩ]", "k"],
        ["[ĺļľŀłƚȴɫɬɭ]", "l"],
        ["[ɱ]", "m"],
        ["[ñńņňƞǹȵɲɳ]", "n"],
        ["[òóôõöøōŏőơǒǫǭǿȍȏȫȭȯȱ]", "o"],
        ["[œ]", "oe"],
        ["[ƥ]", "p"],
        ["[ɋʠ]", "q"],
        ["[ŕŗřȑȓɍɼɽɾ]", "r"],
        ["[śŝşšșȿʂ]", "s"],
        ["[ţťŧƫƭțȶʈ]", "t"],
        ["[ùúûüũūŭůűųưǔǖǘǚǜȕȗ]", "u"],
        ["[ʋ]", "v"],
        ["[ŵ]", "w"],
        ["[ýÿŷƴȳɏ]", "y"],
        ["[źżžƶǅȥɀʐʑ]", "z"],
    ];
    for (var i=0; i<map.length; ++i) {
        s = s.replace(new RegExp(map[i][0], "gi"), ((j)=> {
            return (match) => {
                if (match.toUpperCase() === match) {
                    return map[j][1].toUpperCase();
                } else {
                    return map[j][1];
                }
            }
        })(i));
    }
    return s;
};
const accentsTidyLw=(s)=>{
    return accentsTidy(s).toLowerCase();
};
const filter=(s)=>{
  return accentsTidyLw(s).replace(/[^a-zA-Z0-9]/g,'-');
};

const utils={
    jump,
    cumulativeOffset,
    path2type,
    parseWithLinks,
    nbsp,
    truncateString,
    parseContact,
    filter,
    accentsTidy,
    accentsTidyLw,
}
export default utils;
