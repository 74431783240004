import React, { useEffect, useContext } from "react";
import { useQuery } from '@apollo/client';
import Container from '@material-ui/core/Container';
import './Rubrique.scss'
import {
  useRouteMatch,
  Route,
} from "react-router-dom";
import SubRoutes from "components/Misc/SubRoutes";
import Conf from 'utils/Conf';
import Asso from 'components/Encart/Asso';
import Mention from 'components/Encart/Mention';
import Evenements from 'components/Evenements/Evenements';
import Engager from 'components/Engager/Engager';
import Contact from 'components/Encart/Contact';
import { SettingsContext } from 'Providers/SettingsProvider';
import Encart from 'components/Encart';
import Anchor from 'components/Misc/Anchor';
import Queries from 'utils/Queries';
import CartoucheAgenda from 'components/Agenda/CartoucheAgenda';

function Rubrique({id, subRoutes, exact=false, pathname}) {
  const { updateLoadState,getAriane,getPath,fetchedList } = useContext(SettingsContext);
  const { path } = useRouteMatch();
  const { networkStatus,data,refetch } = useQuery(Queries.rubrique,{
    variables: { id },
  });
  const rubrique=data ? data.rubriques[0] : null;
  const visuel=rubrique ? (rubrique.visuel ? `url(${Conf.apiUrl}/assets/${rubrique.visuel.id}?key=bandeau-wide)` : null) : null;
  const { root } = getAriane(getPath(rubrique));
  const { ariane:arianeFull } = getAriane(pathname);
  let depth=0;
  arianeFull.forEach((item,i)=>{
      if(item.__typename==="rubriques" && item.id===id) depth=i+1;
  });
  const uuid=`rubrique${id}`;
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  useEffect(()=>exact ? document.title = (rubrique ? rubrique.titre : '') : null,[rubrique,exact]);
  const rubriquesTemplates= rubrique ? {
      default:<div>
          {rubrique.id!==root && <div className="rubrique-header">
              <div className="rubrique-header-inner" style={{color:rubrique.color_title,backgroundImage:visuel}}>
                {rubrique.show_titre && <h3>{rubrique.titre}</h3>}
              </div>
          </div>}
          <div className="rubrique-container">
          <Container maxWidth='xl'>
            {rubrique.rubriques.length>0 &&
              rubrique.rubriques.map((rub,i)=><React.Fragment key={i}>
                  <Anchor slug={rub.slugs[0] ? rub.slugs[0].slug : rub.id} rubId={rub.id}/>
                  <Encart data={{titre:rub.titre,texte:rub.descriptif,articles:rub.articles,documents:rub.documents}} template='generique' side={i%2===0 ? 'left' : 'right'}/>
              </React.Fragment>)
            }
            {rubrique.rubriques.length===0 && <>
              <Anchor slug={rubrique.slugs[0] ? rubrique.slugs[0].slug : rubrique.id} rubId={rubrique.id}/>
              <Encart data={{titre:rubrique.titre,texte:rubrique.descriptif,articles:rubrique.articles}} template='generique' side="left"/>
            </>}
          </Container>
          </div>
      </div>,
      rub_1:<div>
          <div className="rubrique-header">
              <div className="rubrique-header-inner" style={{color:rubrique.color_title,backgroundImage:visuel}}>
                {rubrique.show_titre && <h3>{rubrique.titre}</h3>}
              </div>
          </div>
          <div className="rubrique-container">
          <Container maxWidth='xl'>
              <Asso/>
          </Container>
          </div>
      </div>,
      rub_2:<div>
          <div className="rubrique-header">
              <div className="rubrique-header-inner" style={{color:rubrique.color_title,backgroundImage:visuel}}>
                {rubrique.show_titre && <h3>{rubrique.titre}</h3>}
              </div>
          </div>
          <div className="rubrique-container">
          <Container maxWidth='xl'>
              <Mention/>
          </Container>
          </div>
      </div>,
      rub_3:<Evenements/>,
      rub_4:<div>
          <div className="rubrique-header">
              <div className="rubrique-header-inner" style={{color:rubrique.color_title,backgroundImage:visuel}}>
                {rubrique.show_titre && <h3>{rubrique.titre}</h3>}
              </div>
          </div>
          <div className="rubrique-container">
          <Container maxWidth='xl'>
              <Engager titre={false}/>
          </Container>
          </div>
      </div>,
      rub_5:<div>
          <div className="rubrique-header">
              <div className="rubrique-header-inner" style={{color:rubrique.color_title,backgroundImage:visuel}}>
                {rubrique.show_titre && <h3>{rubrique.titre}</h3>}
              </div>
          </div>
          <div className="rubrique-container">
          <Container maxWidth='xl'>
              <div className="spacer"></div>
              <Contact/>
          </Container>
          </div>
      </div>,
  } : {default:<div/>};
  const localRoutes=[
    <Route key='main-rub' exact path={path}>
        {rubrique && (rubriquesTemplates['rub_'+rubrique.id] || rubriquesTemplates.default)}
        <div className="bottom-padding"/>
    </Route>,
  ]
  return rubrique ? <div>
      {!root && depth>0 && <CartoucheAgenda small/>}
      {!exact && depth>0 && <div className="rubrique-header">
          <div className="rubrique-header-inner" style={{color:rubrique.color_title,backgroundImage:visuel}}>
              <h3>{rubrique.titre}</h3>
          </div>
      </div>}
      <SubRoutes id={rubrique.id} localRoutes={localRoutes} depth={depth+1}/>
  </div> : <div/>;
}
export default Rubrique;
