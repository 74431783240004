import React, { useEffect, useState, useRef, useContext, useCallback } from "react";
import './CartoucheAgenda.scss';
import { useQuery } from '@apollo/client';
import { DateTime } from "luxon";
import Queries from 'utils/Queries';
import Utils from 'utils/Utils';
import {
  Link
} from "react-router-dom";
import SlugLink from 'components/Utils/SlugLink';
import { SettingsContext } from 'Providers/SettingsProvider';
import h2p from 'html2plaintext';

export const agendaDates=(ev)=>{
    const from=DateTime.fromISO(ev.date_from);
    const to=DateTime.fromISO(ev.date_to);
    const sameMonth=from.toFormat('M')===to.toFormat('M');
    const stringObj= sameMonth ? { day: 'numeric' } : { month: 'long', day: 'numeric' };
    const formated_from=( to.isValid ? 'Du ' : 'Le ')+from.toLocaleString(stringObj)+' ';
    const formated_to= to.isValid ? 'au '+to.toLocaleString({ month: 'long', day: 'numeric' }) : '';
    return formated_from.replace(' 1 ',' 1er ')+formated_to.replace(' 1 ',' 1er ');
}

function CartoucheAgenda({small=false}) {
  const { screenSize,updateLoadState,fetchedList } = useContext(SettingsContext);
  const { networkStatus,data,refetch } = useQuery(Queries.cartoucheagenda,{
      variables: { date: DateTime.now().plus({ days: -1 }).toFormat('yyyy-LL-dd')},
  });
  const uuid='cartoucheAgenda';
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  const [step,setStep]=useState(0);
  const [hide,setHide]=useState(false);
  const [size,setSize]=useState(small ? 'small':'big');
  const nodeRef=useRef();
  const ref=useRef();
  const [ scrollY, setScrollY ] = useState(0);
  const handleScroll=useCallback(()=>{
      setScrollY(window.scrollY);
      if (nodeRef.current) {
        const rectParent=nodeRef.current.parentNode.getBoundingClientRect();
        if (rectParent.bottom-window.innerHeight<0 && !hide) setHide(true);
        if (rectParent.bottom-window.innerHeight>=0 && hide) setHide(false);
      }
  },[hide,setHide]);
  useEffect(()=>{
      document.addEventListener('scroll',handleScroll);
      return ()=>document.removeEventListener('scroll',handleScroll);
  },[handleScroll]);
  useEffect(()=>{
    const next=()=>{
        setStep((step)=>{
          if (ref.current) {
            const rectNode=ref.current.getBoundingClientRect();
            const rectParent=ref.current.parentNode.getBoundingClientRect();
            if (rectNode.height>rectParent.height) return step+1
          }
          return 0
        })
    }
    const interval=setInterval(next,5000);
    return ()=>clearInterval(interval);
  },[setStep,data]);
  let threshold=750;
  if (screenSize==='sm') threshold=750;
  if (screenSize==='md') threshold=630;
  if (screenSize==='lg') threshold=700;
  if (screenSize==='xl') threshold=750;
  useEffect(()=>{
    if (small) {
      if (size==='small' && scrollY<=threshold) setSize('big');
      if (size==='big' && scrollY>threshold) setSize('small');
    }
  },[scrollY,size,setSize,small,threshold,hide,setHide]);
  let classes="cartouche-agenda";
  if (scrollY>threshold) classes+=" small";
  if (small) classes+=" fixed";
  if (hide) classes+=" hide";
  return <div className={classes} ref={nodeRef}>
    <div className="cartouche-agenda-inner">
    <div className="disque1"></div>
    <div className="disque2"></div>
    <div className="content">
        <div className="shape-mask"></div>
        <div ref={ref}>
        {data && data.interface && <Link to="/actualites"><h3 className="agenda-title">{data.interface[0].value}</h3></Link>}
        {size==='big' && data && data.agenda && (data.agenda.length>2 ? data.agenda : data.fallback).map((ev,i)=>{
          let classes="evt";
          if (i<step) classes+=" hide";
          return <div key={ev.id} className={classes}>
                {ev.affdate && <div className="event-dates">{agendaDates(ev)}</div>}
                <SlugLink item={ev}><h3 className="event-title" style={{color:ev.type && ev.type.couleur}}>{Utils.truncateString(Utils.parseWithLinks(ev.title),40)}</h3></SlugLink>
                <div className="event-texte">{Utils.truncateString(Utils.parseWithLinks(h2p(ev.resume)),150)}</div>
                <div className="spacer-mini"/>
            </div>;
        })}
        </div>
    </div>
  </div>
  </div>;
}

export default CartoucheAgenda;
