import React from "react";
import BlocTexte from 'components/Blocs/BlocTexte';
import Blocs from 'components/Blocs/Blocs';

function InnerArticle({article}) {
  return <>
        <h1>{article.titre}</h1>
        <BlocTexte item={article}/>
        <Blocs blocs={article.blocs}/>
  </>;
}
export default InnerArticle;
