import React from "react";
import { Textfit } from 'react-textfit';

function FontSizer ({
  onChange,
}) {
  return <div style={{width:'10%',height:0,overflow:'hidden'}}><Textfit mode="single" max={1000} min={0} onReady={onChange}>A</Textfit></div>;
};

export default FontSizer;
