import React, { useContext, useEffect } from "react";
import InnerArticle from 'components/Article/InnerArticle';
import { useQuery } from '@apollo/client';
import Queries from 'utils/Queries';
import './Preview.scss';
import 'components/Article/Article.scss';
import { SettingsContext } from 'Providers/SettingsProvider';

function PreviewArticle({ article }) {
  const { updateLoadState,fetchedList } = useContext(SettingsContext);
  const { networkStatus,data,refetch } = useQuery(Queries.article,{
    variables: { id:article.id },
  });
  const uuid=`article${article.id}`;
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  const articleServer=data ? data.articles[0] : null;
  if (articleServer) {
      let {blocs:blocsServer,...othersServer} = articleServer;
      let {blocs,...others} = article;
      let articleUpdated={...othersServer,...others,blocs:[]};
      if (blocs.length>0) {
          blocs.forEach((b,i)=>{
              if (b.collection) {
                let bs=blocsServer.find((o)=>o.id===b.id);
                let { item, ...otherProps } = b;
                if (bs) {
                  let { item:itemServer, ...otherPropsServer } = bs;
                  if (item.documents && itemServer.documents) {
                      let newDocuments=item.documents.map((doc)=>doc.directus_files_id ? doc : itemServer.documents.find((docServer)=>doc===docServer.id));
                      item.documents=[...newDocuments];
                  }
                  articleUpdated.blocs.push({item:{...itemServer,...item},...otherPropsServer,...otherProps});
                } else {
                  articleUpdated.blocs.push({item:{...item,__typename:b.collection},...otherProps});
                }
              } else {
                articleUpdated.blocs.push(blocsServer.find((o)=>o.id===b));
              }
          });
      }
      articleUpdated.blocs.sort((a,b)=>a.sort-b.sort);
      return <div className="article">
        <InnerArticle article={articleUpdated}/>
      </div>;
  } else {
    return '';
  }
}
export default PreviewArticle;
