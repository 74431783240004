import React, { useContext } from "react";
import CartoucheImage from 'components/Misc/CartoucheImage';
import Conf from 'utils/Conf';
import './TplContact.scss';
import Grid from '@material-ui/core/Grid';
import { SettingsContext } from 'Providers/SettingsProvider';
import Utils from 'utils/Utils';

function TplContact({ data, fontSize }) {
  const { screenSize } = useContext(SettingsContext);
  const { rub } = data;
  const factor=screenSize==="xs" || screenSize==="sm" ? 2.5 : 1 ;
  return <div className='contact-layout'>
      <div className='contact-layout-inner'>
          <Grid container spacing={2}>
              <Grid item xs={12} md={6} className="contact-layout-0">
                  {rub && <h3 style={{fontSize:(fontSize*0.2*factor)+'px'}}>{rub.titre}</h3>}
                  {rub && <div className="contact-texte" style={{fontSize:(fontSize*0.1*factor)+'px'}}>{Utils.parseWithLinks(rub.descriptif)}</div>}
                  <div className="spacer-mini"/>
                  <div className="spacer-mini"/>
              </Grid>
              <Grid item xs={12} md={6}>
                  <div className='contact-medium'>
                      {rub && <CartoucheImage url={rub.visuel ? `${Conf.apiUrl}/assets/${rub.visuel.id}?key=medium` : null }/>}
                  </div>
              </Grid>
          </Grid>
      </div>
      </div>;
}
export default TplContact;
