import React, { useContext } from "react";
import './Foreground.scss'
import Conf from 'utils/Conf';
import { SettingsContext } from 'Providers/SettingsProvider';

function Foreground({children}) {
  const { root } = useContext(SettingsContext);
  let classes="foreground";
  Conf.special.forEach((spe)=>{
      if (root===spe.id) classes+=' '+spe.class;
  });
  return <div className={classes}>
    {children}
  </div>;
}
export default Foreground;
