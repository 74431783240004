import React, { useEffect, useContext, useState } from "react";
import { useQuery } from '@apollo/client';
import Container from '@material-ui/core/Container';
import './Actualites.scss'
import Queries from 'utils/Queries';
import { SettingsContext } from 'Providers/SettingsProvider';
import Grid from '@material-ui/core/Grid';
import {agendaDates} from 'components/Agenda/CartoucheAgenda'
import SlugLink from 'components/Utils/SlugLink';
import Conf from 'utils/Conf';
import Utils from 'utils/Utils';
import {
  Link
} from "react-router-dom";
import { DateTime } from "luxon";
import h2p from 'html2plaintext';

function Actualites({id, subRoutes, ariane, root}) {
  const { networkStatus,data,refetch } = useQuery(Queries.actualites,{
      variables: { date: DateTime.now().plus({ days: -1 }).toFormat('yyyy-LL-dd')},
  });
  const agenda=data ? data.agenda : [];
  const types=data ? data.evt_type : [];
  const { t,setCurrentRub,updateLoadState,fetchedList,screenSize} = useContext(SettingsContext);
  const [ selection, setSelection ] = useState([]);
  useEffect(()=>setCurrentRub(0),[setCurrentRub]);
  const uuid='actualites';
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  const selected=selection.length===0 ? agenda : agenda.filter((evt)=>selection.indexOf(evt.type.type)!==-1);
  const handleChange=(type)=>{
      setSelection((selection)=>{
          let tab=[...selection];
          const idx=tab.indexOf(type)
          if (idx===-1) tab.push(type);
          else tab.splice(idx,1);
          return tab;
      });
  }
  const tr= t("actualites");
  useEffect(()=>document.title = tr,[tr]);
  const [ page, setPage ] = useState(0);
  const itemsParPage=screenSize==='lg' ? 6 : 8;
  useEffect(()=>setPage(0),[selection,setPage,itemsParPage]);
  const pages=Math.ceil(selected.length/itemsParPage);
  const first=page*itemsParPage+1;
  const last=Math.min(selected.length,page*itemsParPage+itemsParPage);
  const actual=selected.filter((o,i)=>i>=first-1 && i<=last-1);
  const next=()=>{
    console.log(page+1,pages-1);
    setPage(Math.min(page+1,pages-1));
  }
  const prev=()=>{
    setPage(Math.max(page-1,0));
  }
  return agenda ? <div className="actualites">
    <Container maxWidth='xl'>
        <h1>{t("actualites")}</h1>
        <div className="actu-footer">
            <Link to="/actualites-archives">{t('actualites-archives')}</Link>
        </div>
        <div className="actualites-filters">
            {types.map((type)=>{
                let classes="actualites-filter";
                if (selection.length===0 || selection.indexOf(type.type)!==-1) classes+=" active";
                return <span key={type.type} className={classes} style={{backgroundColor:type.couleur}} onClick={()=>handleChange(type.type)}>{type.type}</span>
            })}
        </div>
        {selected.length>itemsParPage && <p className="pagination">{first} à {last} de {selected.length} <span onClick={prev} className="prevnext">&lt;</span>  <span onClick={next} className="prevnext">&gt;</span></p>}
        <Grid container spacing={2}>
            {actual.map((evt,i)=>{
                const visuel=evt ? (evt.visuel ? <img alt="" src={`${Conf.apiUrl}/assets/${evt.visuel.id}?key=bandeau-small`}/> : '') : '';
                return <Grid key={evt.id} item xs={12} sm={6} lg={4} xl={3} className="actualites-evt-container">
                  <div className="actualites-evt">
                  {visuel ? <SlugLink item={evt}><div className="actualites-evt-bandeau">
                      {visuel}
                  </div></SlugLink> : <div className="spacer-mini"/> }
                  {evt.affdate && <div className="actualites-evt-dates">{agendaDates(evt)}</div>}
                  <SlugLink item={evt}><h3 style={{color:evt.type.couleur}}>{Utils.truncateString(Utils.parseWithLinks(evt.title),80)}</h3></SlugLink>
                  <div className="actualites-evt-text">{Utils.truncateString(Utils.parseWithLinks(h2p(evt.resume)),200)}</div>
                  <div className="actualites-evt-type" style={{backgroundColor:evt.type.couleur}}>{evt.type.type}</div>
                  <SlugLink item={evt}><div className="actualites-evt-plus" style={{color:evt.type.couleur}}>en savoir plus</div></SlugLink>
                  </div>
              </Grid>;
            })}
        </Grid>
        {selected.length>itemsParPage && <p className="pagination"><span onClick={prev} className="prevnext">&lt;</span>  <span onClick={next} className="prevnext">&gt;</span></p>}
    </Container>
    <div className="bottom-padding"/>
  </div> : <div><div className="bottom-padding"/></div>;
}
export default Actualites;
