import React, { useEffect, useContext } from "react";
import { useQuery } from '@apollo/client';
import './Blocs.scss';
import Conf from 'utils/Conf';
import filesize from 'filesize';
import mime from 'mime-types';
import Queries from 'utils/Queries';
import { SettingsContext } from 'Providers/SettingsProvider';

function BlocDocuments({item}) {
  //console.log(item);
  const { updateLoadState,fetchedList,clientSystem } = useContext(SettingsContext);
  const {documents,label} = item || {};
  const ids=documents.map((doc)=>doc.directus_files_id.id || doc.directus_files_id);
  const uuid=`documents-${ids.join(',')}`
  const { networkStatus,data,refetch } = useQuery(Queries.documents,{
    variables: { ids:ids },
    client:clientSystem,
  });
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  //console.log(documents);
  return <div className="bloc bloc-documents">
    {label && <div className="bloc-documents-label">{label}</div>}
    {documents && documents.map((doc,i)=>{
      const docId=doc.directus_files_id.id || doc.directus_files_id;
      const document=data ? data.documents.find((d)=>d.id===docId):null;
      const url=`${Conf.apiUrl}/assets/${docId}`;
      return document ? <div className="document-container" key={docId}>
          <a href={url} target="_blank" rel="noreferrer">{document.title}</a> ({mime.extension(document.type)}) {filesize(document.filesize)}
      </div> : '';
    })}
  </div>;
}
export default BlocDocuments;
