import React from "react";
import SlugLink from 'components/Utils/SlugLink';
import CartoucheImage from 'components/Misc/CartoucheImage';
import Conf from 'utils/Conf';
import './TplMention.scss';
import Utils from 'utils/Utils';

function TplMention({ data, fontSize }) {
  const { rub, art1, art2, art3, art4 } = data;
  return <div className='mention-layout'>
      <div className='mention-layout-inner'>
          <div className='mention-layout-0'>
              {rub && <h3 style={{fontSize:(fontSize*0.2)+'px'}}>{rub.titre}</h3>}
              {rub && <div style={{fontSize:(fontSize*0.1)+'px'}}>{Utils.parseWithLinks(rub.descriptif)}</div>}
          </div>
          <SlugLink item={art1} type='article'>
              <div className='mention-layout-1'>
                      {art1 && <CartoucheImage texte={art1.titre} url={art1.visuel ? `${Conf.apiUrl}/assets/${art1.visuel.id}?key=medium` : null } fontSize={fontSize*0.1}/>}
              </div>
          </SlugLink>
          <SlugLink item={art2} type='article'>
              <div className='mention-layout-2'>
                  {art2 && <CartoucheImage texte={art2.titre} url={art2.visuel ? `${Conf.apiUrl}/assets/${art2.visuel.id}?key=medium` : null } fontSize={fontSize*0.1}/>}
              </div>
          </SlugLink>
          <SlugLink item={art3} type='article'>
              <div className='mention-layout-3'>
                  {art3 && <CartoucheImage texte={art3.titre} url={art3.visuel ? `${Conf.apiUrl}/assets/${art3.visuel.id}?key=medium` : null } fontSize={fontSize*0.1}/>}
              </div>
          </SlugLink>
          <SlugLink item={art4} type='article'>
              <div className='mention-layout-4'>
                  {art4 && <CartoucheImage texte={art4.titre} url={art4.visuel ? `${Conf.apiUrl}/assets/${art4.visuel.id}?key=medium` : null } fontSize={fontSize*0.1}/>}
              </div>
          </SlugLink>
      </div>
      </div>;
}

export default TplMention;
