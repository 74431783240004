import React, { useContext } from "react";
import './NotFound.scss'
import { SettingsContext } from 'Providers/SettingsProvider';
import Utils from 'utils/Utils';
import Container from '@material-ui/core/Container';

function NotFound() {
  const { t } = useContext(SettingsContext);
  return <div>
    <Container maxWidth='xl'>
      <div className='article'>
        {Utils.parseWithLinks(t('notfound','text'))}
      </div>
    </Container>
    <div className="bottom-padding"/>
  </div>;
}
export default NotFound;
