import React, { useContext } from "react";
import './ProTile.scss';
import Image from 'components/Image';
import Utils from 'utils/Utils';
import Conf from 'utils/Conf';
import { SettingsContext } from 'Providers/SettingsProvider';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import Chip from '@material-ui/core/Chip';
import Md from './Md';
import SwiperCore, { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
// install Swiper modules
SwiperCore.use([Navigation, Pagination, A11y]);

function ProTile({c,onClose,schema}) {
  const { t } = useContext(SettingsContext);
  const {mainName,fullName,diaporama,raison,adresse,cp,ville,mail,tel,descriptif,data}=Utils.parseContact(c);
  const label=(o)=>{
    const trad=t('form-'+Utils.filter(o.name));
    return trad.length!==0 ? trad : o.label
  }
  const choix=(o)=>{
    const trad=t('choix-'+Utils.filter(o.valeur));
    return trad.length!==0 ? trad : o.nom
  }
  const multItem=(o)=><Chip key={o.id} size="small" color="secondary" style={{marginRight:"5px", marginBottom:"5px"}} label={choix(o)}/>;
  return <div className='pro-tile'>
    <div className='pro-titre'>{mainName}</div>
    {diaporama.length>0 && <div className='pro-diaporama'><Swiper
      navigation
      pagination={{ clickable: true }}
      >
        {diaporama.map((image,i)=>{
          return <SwiperSlide key={image.url}>
            <Image url={Conf.contactsApi+'/'+image.url} alt="" ratio={0.5} params={image.params}/>
          </SwiperSlide>
        })}
        {diaporama.length===0 && <SwiperSlide>
          <Image url={null} alt="" ratio={0.5}/>
        </SwiperSlide>}
      </Swiper>
    </div>}
    <div className='pro-content'>
      {mainName!==fullName && <h3>{fullName}</h3>}
      {mainName!==raison && <h3>{raison}</h3>}
      <div className="pro-content-contact">
        {adresse && <div>
        <div>{adresse}</div>
        {cp} {ville}
        </div>}
        {mail && <div className="mail"><MailIcon/><span>{mail}</span></div>}
        {tel && <div className="tel"><PhoneIcon/><span>{tel}</span></div>}
      </div>
      <div className="pro-content-descriptif"><Md text={descriptif}/></div>
      {schema && schema.filter((p)=>p.id==='et4u0dohq').map((page) => <div key={page.id}>
        {page.elts.map((elt)=>{
          const item=data.find((o)=>o.id===elt.id);
          if (elt.type==='tab') {
            const tab=item.valeur.tab;
            return <div key={elt.id}>
            <div className="label">{label(elt)}</div>
            <div>
              {tab.map((ligne,i)=><div key={i}>
                {Object.keys(ligne).filter((k)=>k!=='uuid').map(k=>{
                  const o=ligne[k];
                  const so=elt.elts.find((e)=>e.id===k);
                  if (so.type==='multiples') return o.valeur ? <div key={k}>
                    {so.choix.filter((c)=>o.valeur.split(',').indexOf(c.valeur)!==-1).map((ch)=>multItem(ch))}
                  </div> : null;
                  return o.valeur ? <div key={k} className="valeur-texte"><Md text={o.valeur}/></div> : null;
                })}
              </div>)}
              </div>
            </div>
          }
          if (elt.type==='multiples') {
            const ch=elt.choix.filter((o)=>item.valeur.split(',').indexOf(o.valeur)!==-1);
            return item.valeur ? <div key={elt.id}>
              <div className="label">{label(elt)}</div>
              {ch.map((o)=>multItem(o))}
            </div> : null;
          }
          return item.valeur ? <div key={elt.id}>
            <div className="label">{label(elt)}</div>
            <div className="valeur-texte"><Md text={item.valeur}/></div>
          </div> : null;
        })}
      </div>)}
    </div>
    <div className="languette">
      <div onClick={onClose}>
        {t('tous-les-producteurs')}
      </div>
    </div>
  </div>;
}
export default ProTile;
