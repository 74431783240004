import React, { useState, createContext, useEffect, useMemo } from "react";
import parse from 'html-react-parser';
import { useQuery } from '@apollo/client';
import Queries from 'utils/Queries';
import {
  useLocation,
} from "react-router-dom";

export const SettingsContext = createContext({});

let producteurs=[];
if (window.localStorage) {
  let p=null;
  try {
    p=JSON.parse(window.localStorage.getItem('nptarn-producteurs'));
  } catch (e) {
    console.log(e);
  }
  if (Array.isArray(p)) producteurs=p;
}

const doUpdateLoadState=(key,networkStatus,setLoadlist, setFetchedList)=>{
    setFetchedList((list)=>{
        if (!Array.isArray(list)) list=[];
        let idx=list.indexOf(key);
        if (idx===-1) return [...list,key];
        return list;
    });
    if (networkStatus===1) setLoadlist((list)=>{
        if (!Array.isArray(list)) list=[];
        let idx=list.indexOf(key);
        if (idx===-1) return [...list,key];
        return list;
    });
    if (networkStatus!==1) setTimeout(()=>setLoadlist((list)=>{
        if (!Array.isArray(list)) list=[];
        let idx=list.indexOf(key);
        if (idx!==-1) {
            let res=list.reduce((acc,l,i)=>(i!==idx) ? [...acc,l]: acc ,[]);
            return res;
        }
        return list;
    }),500);
}
const getPathFromData=(item, data) => {
    const rubriques = data ? data.rubriques : [];
    const articles = data ? data.articles : [];
    const itemModel = Boolean(item) ? (item.__typename==='articles' ? articles.find((o)=>o.id===item.id) : (
        item.__typename==='rubriques' ? rubriques.find((o)=>o.id===item.id) : {...item}
    )) : null ;
    let maxDepth=5;
    let currentDepth=0;
    let currentRub=itemModel && itemModel.rubrique ? itemModel.rubrique.id : null;
    let path='/';
    const itemSlug=itemModel ? (itemModel.slugs && itemModel.slugs[0] ? itemModel.slugs[0].slug : itemModel.id) : null;
    if (itemModel && itemModel.__typename==='rubriques') path='/'+itemSlug;
    if (itemModel && itemModel.__typename==='articles') path='/article-'+itemSlug;
    if (itemModel && itemModel.__typename==='agenda') return '/actualites/'+itemSlug;
    while (currentDepth<maxDepth && currentRub!==null) {
        currentDepth++;
        let rub=currentRub;
        let parent=rubriques.find((o)=>o.id===rub);
        let slug= parent ? ( parent.slugs && parent.slugs[0] ? parent.slugs[0].slug : parent.id ) : null;
        path=`/${slug}${path}`;
        if (parent) currentRub=parent.rubrique ? parent.rubrique.id : null;
    }
    return path;
}
const getArianeFromData=(pathname, data) => {
      const rubriques = data ? data.rubriques : [];
      const articles = data ? data.articles : [];
      const tab=pathname.split('/');
      let ariane=[];
      let isPage=false;
      if (tab[0]==='') tab.splice(0,1);
      if (!tab[0].startsWith('actualites')) {
          tab.forEach((path, i) => {
              let pathTab=path.split('-');
              const type=pathTab[0];
              let slug='';
              let item=null;
              if (type==='article') {
                  pathTab.shift();
                  slug=pathTab.join('-');
                  item=articles.find((o)=>(o.slugs && o.slugs.reduce((a,c)=>a || c.slug===slug, false)) || o.id===parseInt(slug));
              }
              else {
                  slug=pathTab.join('-');
                  item=rubriques.find((o)=>(o.slugs && o.slugs.reduce((a,c)=>a || c.slug===slug, false)) || o.id===parseInt(slug));
              }
              if (item) ariane.push(item);
          });
      } else {
          isPage=true;
      }
      let rootIdx=0;
      let root=null;
      ariane.forEach((item, i) => {
          if(item.__typename==='rubriques' && item.type==='event') {
              rootIdx=i;
              root=item.id;
          }
      });
      return {
        ariane:(root ? ariane.reduce((a,o,i)=>i>rootIdx ? [...a,o] : a,[]) : [...ariane]),
        isPage:isPage,
        root:root
      }
}

const SettingsProvider = ({clientSystem,children})=>{
    const { pathname } = useLocation();
    const [ appState, setAppState ] = useState({
      cartePage:1,
      producteurs,
      filter:{
        text:'',
        schema:{},
      },
    });
    const [ screenSize, setScreenSize ] = useState("xl");
    const [ mustload, setMustload ] = useState(true);
    const [ ready, setReady ] = useState(false);
    const [ loadlist, setLoadlist ] = useState(null);
    const [ fetchedList, setFetchedList ] = useState([]);
    const [ currentRub, setCurrentRub ] = useState(1);
    const { networkStatus,data,refetch } = useQuery(Queries.settings);
    const updateLoadState=useMemo(()=>
      (key,networkStatus)=>doUpdateLoadState(key,networkStatus,setLoadlist,setFetchedList),
      [setLoadlist,setFetchedList]
    );
    const uuid='settings';
    useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
    useEffect(()=>{
      if(fetchedList.indexOf(uuid)===-1){
        console.log('fetch',uuid);
        refetch();
      }
    },[refetch,fetchedList,uuid]);
    const getPath=useMemo(()=>(item)=>getPathFromData(item,data),[data]);
    const getAriane=useMemo(()=>(pathname)=>getArianeFromData(pathname,data),[data]);
    const [ { ariane, root, isPage }, setAriane] = useState(getAriane(pathname));
    const trads=data ? data.interface.reduce((acc,item,i)=>{
        const key=item.key;
        const value=item.value;
        const text=item.texte;
        return {[key]:{value,text},...acc};
    },{}) : {};
    const t=(key,type='value')=>{
        const tr=trads[key] ? trads[key] : {value:null, text:null};
        let res=tr[type] || '';
        res=res.replace(/\*([^*]+)\*/gm,'<span class="highlight">$1</span>');
        return parse(res);
    };
    useEffect(()=>{
        const resize=()=>{
            const width=window.innerWidth;
            let size="xs";
            if (width>576) size="sm";
            if (width>768) size="md";
            if (width>992) size="lg";
            if (width>1200) size="xl";
            setScreenSize(size);
        }
        resize();
        window.addEventListener('resize',resize);
        return ()=>window.removeEventListener('resize',resize);
    },[setScreenSize]);
    useEffect(()=>{
        if (Array.isArray(loadlist) && loadlist.length>0) setMustload(false);
        if (!ready && Array.isArray(loadlist) && loadlist.length===0) setReady(true);
    },[loadlist,setReady,ready]);
    useEffect(()=>setAriane(getAriane(pathname)),[pathname,data,getAriane]);
    useEffect(()=>{
      setMustload(true);
      setTimeout(()=>setMustload(false),100);
    },[pathname]);
    useEffect(()=>{
      if (root && ariane.length===0) {
        setCurrentRub(root);
      }
      if (ariane.length>0) {
        setCurrentRub(ariane[0].id);
      }
    },[ariane,root,setCurrentRub])
    const loaded=!mustload && (Array.isArray(loadlist) ? loadlist.length===0 : false);
    const value={site:data ? data.site : {}, appState, setAppState, clientSystem, fetchedList, screenSize, trads, t, currentRub, setCurrentRub, loadlist, loaded, ready, ariane, root, pathname, isPage, updateLoadState, getPath, getAriane};
    let classes="settings-container";
    if (ready || pathname==="/preview") classes+=" ready";
    if (loaded) classes+=" loaded";
    let waitClasses="wait";
    if (ready || pathname==="/preview") waitClasses+=" ready";
    useEffect(()=>{
      if (ready) document.body.classList.add('ready');
      else document.body.classList.remove('ready');
    },[ready])
    useEffect(()=>{
      const t=setTimeout(()=>setReady(true),3000);
      return ()=>clearTimeout(t);
    },[setReady]);
    return (
        <SettingsContext.Provider value={value}>
            <div className={waitClasses}>
              <div className="logo">
                <span>Chargement en cours...</span>
                <div className="logo-img">
                  <span>TARN</span>
                </div>
              </div>
            </div>
            {data && <div className={classes}>{children}</div>}
        </SettingsContext.Provider>
    );
}
export default SettingsProvider;
